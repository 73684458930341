<template>
  <div class="item" v-if="mostrar">
      <div class="col-flex">
        <router-link :to="{name: 'descargas', params: {code: code} }">
          <div class="caja">
            <img :src="imagen">
            <div class="descr">
              <p>{{nombre}}</p>
            </div>
          </div>
          </router-link>
        </div>
      <router-view></router-view>
    </div>
</template>

<script>

  export default {
    name: "item",
    data() {
      return {
      };
    },

    props: {
      code: String,
      nombre: String,
      mostrar: Boolean
    },

    computed: {
        imagen() {
            return require('@/assets/descargas/revit/'+ this.code + '.jpg')
        }
    }
  };

</script>

<style scoped>

.col-flex {
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 30px;
  flex: 0 0 auto;
  margin-right: 25px;
}

@media (max-width: 570px) {
  .col-flex {
  margin: auto;
  margin-right: 10px;
  margin-right: 10px
  }
}

  .descr {
    height: 80px;
    text-align: left;

  }

  .caja  {
    margin-top: 25px;
    box-sizing: border-box;
    height: 320px;
    width: 225px;
    background: rgb(255,255,255);
    background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 50%, rgba(186,186,186,1) 100%);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    cursor: pointer;
  }

  .caja p  {
    font-family: 'Open Sans', sans-serif;
    font-size: 15px;
    margin-left: 12px;
    margin-right: 12px;
    margin-top: 5px;
    margin-bottom: 0px;
    color: #2c3e50;
    line-height: 1.4em;
    font-weight: 500;  }

  a {
      text-decoration: none;
  }


  .caja:hover {
    -webkit-box-shadow: inset 0px 0px 17px 0px rgba(0,0,0,0.74);
    box-shadow: inset 0px 0px 17px 0px rgba(0,0,0,0.40);
  }


  img {
    height: 190px;
    width: 190px;
    border: 3px solid lightgray;
    margin-top: 11px;
    background: white;
    margin-bottom: 0px;
    padding: 0;
  }


</style>
