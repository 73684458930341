
const categorias = [
'Dragados',
'Escolleras en talud',
'Escolleras en banquetas de cajones',
'Todo uno',
'Relleno',
'Pedraplén',
'Enrase con grava',
'Mejora del terreno y cimentaciones',
'Geotextil',
'Excavaciones',
'Demoliciones y retiradas',
'Bloques de hormigón',
'Hormigón para armar',
'Hormigón en masa',
'Hormigón sumergido',
'Acero para armaduras',
'Cajones',
'Pilotes',
'Tablestacas',
'Pavimentación: Bases y sub-bases',
'Pavimentación: Pavimentos de hormigón',
'Pavimentación: Pavimentos bituminosos',
'Pavimentación: Pavimentos de adoquín, de losetas y bordillos',
'Bolardos',
'Aristones y Cantoneras',
'Defensas',
'Escalas',
'Carriles',
'Línea FFCC',
'Piezas especiales',
'Superficie útil',
'Superficie Construida',
'Demoliciones y labores previas',
'Movimiento de tierras',
'Firmes de calzadas',
'Pavimientos de zonas peatonales',
'Muros',
'Saneamiento y drenaje',
'Abastecimiento de agua',
'Redes de servicios',
'Alumbrado público',
'Mobiliario urbano',
'Señalización y balizamiento',
'Conservación',
'Mano de obra',
'Maquinaria',
'Materiales y equipos alta densidad',
]

export default categorias
