
import { createRouter, createWebHashHistory } from "vue-router";
import tienda from "../views/tienda.vue";

const routes = [
  {
    path: "/",
    name: "tienda",
    component: tienda,
  },

  {
    path: "/descargas/:code",
    name: "descargas",
    props: true,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "descargas" */ "../views/descargas.vue"),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes
});

export default router;
