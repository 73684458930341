<template>
<div>


<div class="linea_marino"></div>
<div class="linea_gris">
  <div id="navegacion">
    <router-link to="/">Catálogo BIM</router-link>
  </div>
</div>
<router-view></router-view>
</div>

</template>

<script>

	export default {
		name: "umaTop",
		data() {
			return {
			}
		}
	}

</script>

<style lang="css" scoped>

#top {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  margin-top: 0px;
  padding: 0px;
}

#logo_uma {
  width:270px;
  height:auto;
  margin-left: 50px;
  padding-top: 10px;
  padding-bottom: 4px;
}

#logo_uma_div {
  flex: 1 0;
}

#logo_umaes_div {
  flex: 1 0;
}

#logo_umaes {
  width:250px;
  height:auto;
  margin-left: 20px;

}

#logo_BIM {
  width:85px;
  height:auto;
  align-self: center;
  margin-left: 20px;
  padding-top: 15px;
}

#logo_BIM_div {
  flex: 1 0;
}

#emptybox {
  flex: 1 0;
  text-align: right;
}

.boton {
  background-color: #003466;
  border: none;
  color: white;
  padding: 8px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
}

.boton:active {
  background: #002C56;
}


.idioma_button {
  margin-right: 20px;
  padding-left: 47.5px;
  padding-right: 47.5px;
}

.sesion_button {
  margin-right: 10px;
}

.linea_marino {
  background-color: #003466;
  height: 35px;
  width: 100%;
}

.linea_gris {
  background-color: darkgray;
  width: 100%;
}

#navegacion {
  padding-top: 10px;
  padding-bottom: 10px;
}

#navegacion a {
  font-weight: bold;
  font-size: 1.1em;
  color: white;
  text-decoration: none;
  position: relative;
  margin-right: 0.4em;
  margin-left: 0.3em;
}

#navegacion a::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 100%;
  height: 2.5px;
  background-color: white;
  transform: scaleX(0);
  transform-origin: right;
  transition: transform 250ms ease-in;
}

#navegacion a:hover::after {
  transform: scaleX(1);
  transform-origin: left;
}

#navegacion a.router-link-exact-active {
  color: #003466;
}

#navegacion a.router-link-exact-active::after {
  background-color: #003466;
}

@media (max-width: 1200px) {
  #logo_umaes_div {
    display: none;
  }
}

@media (max-width: 850px) {
  #logo_BIM_div {
    display: none;
  }

  #logo_uma  {
    width:70%;
    height:auto;
    display:block;
    margin:auto;
    padding-top: 5px;
    padding-bottom: 5px;
}

  #logo_uma_div  {
    width: 100%;
}

  #top {
    flex-flow: column wrap;
    background: white;
    width: 100%;
  }

  #language  {
    text-align: center;
    margin-bottom: 10px;
}

  .idioma_button {
    margin-right: 0px;
}

  .sesion_button {
    margin-right: 0px;
  }

  #sesion  {
    padding-bottom: 20px;
    text-align: center;
    width: 100%;
  }
}

@media (max-width: 350px) {
  #navegacion {
    width: 90%
  }
}

</style>
