<template>
  <div>

    <div class="searchBarDiv">
      <div class="searchBar">
        <div class="forAnimation">
          <form>
          <input class="search" type="search" @input="search" @change="search" placeholder="Busqueda por nombre..." list="nombrez" ref="input1"/>
          <datalist id="nombrez">
            <option v-for="nombre in listaNombres" :key="nombre" :value="nombre"/>
          </datalist>
          </form>
          <div v-if="loEscrito != ''" v-on:click="borrarYactualizar('input1')" class="laX">
            <p class="X">X</p>
          </div>
        </div>
      </div>

    </div>


    <div class="dropDiv">
      <div class="forAnimation2">
          <form>
          <input class="categoriaInput" type="search" @input="search" @change="search" placeholder="Categorías..." list="cate" ref="input2"/>
          <datalist id="cate">
            <option v-for="categoria in lCategorias" :key="categoria" :value="categoria" />
          </datalist>
          </form>
          <div v-if="laCategoria != ''" v-on:click="borrarYactualizar('input2')" class="laX">
            <p class="X">X</p>
          </div>
        </div>
    </div>

    <div class="flex-container">
      <item v-for="oneBim in bims" :key="oneBim.codigo" v-bind:code="oneBim.codigo" v-bind:nombre="oneBim.nombre" v-bind:mostrar="mostrar(oneBim.nombre)"></item>
    </div>
  </div>
</template>

<script>

  import bims from './listaBim.js'
  import categorias from './categorias.js'
  import item from './item.vue'

  export default {
    name: "catalogo",
    components: {
      item
    },
    data() {
      return {
        bims: bims,
        filtro: [],
        loEscrito: '',
        laCategoria: '',
        lCategorias: categorias,
        listaCategoria: [],
        listaNombres: []
      };
    },

    mounted(){
       this.search()
     },

    methods: {

      search()  {
        this.listaNombres = this.list;
        this.loEscrito = this.$refs.input1.value;
        this.laCategoria = this.$refs.input2.value;
        this.listaNfiltrada = this.filteredListNames(this.loEscrito);
        this.listaCfiltrada = this.filteredListCategorias(this.laCategoria);
        this.filtro = this.filtrar()
      },

      borrarYactualizar(inputSelected)  {
        if (inputSelected === 'input1'){
          this.$refs.input1.value = '';
        }

        else if (inputSelected === 'input2'){
          this.$refs.input2.value = '';
        }

        this.search()
      },

      filteredListNames(text) {
        var output = [];
        for (var i=0; i < this.list.length ; ++i){
            if (this.list[i].includes(text)) {
              output.push(this.list[i]);
            }
        }
        console.log('ListaNombresFiltrados:')
        console.log(output.length)
        return output;
      },


      filteredListCategorias(text) {
        var listaCatFiltrada = [];
        var output = [];
        for (var i=0; i < this.lCategorias.length ; ++i){
            if (this.lCategorias[i].includes(text)) {
              listaCatFiltrada.push(this.lCategorias[i]);
            }
        }
        console.log('ListaCatFiltrada:')
        console.log(listaCatFiltrada.length)
        for (var j=0; j < this.bims.length ; ++j){
            if (listaCatFiltrada.includes(this.bims[j].categoria)){
              output.push(this.bims[j].nombre);
            }
        }
        console.log('ListaNombresDeCat:')
        console.log(output.length)
        return output;
      },

      mostrar(nombre)  {
        if (this.filtro.includes(nombre)){
          return true
        }
        else{
          return false
        }
      },


      filtrar() {

        if ( this.laCategoria === ''){
          output = this.listaNfiltrada;
          return output
        }

        else {
          var output = [];
          for (var i=0; i < this.listaNfiltrada.length ; ++i){
              if (this.listaCfiltrada.includes(this.listaNfiltrada[i])){
                output.push(this.listaNfiltrada[i]);
              }
          }
        return output;
        }
      }

    },



    computed: {

      list() {
        var output = [];
        for (var i=0; i < this.bims.length ; ++i)
            output.push(this.bims[i].nombre);
        return output;
      }

    },
  };

</script>

<style scoped>

.X {
 text-align: center;
 margin: 0;
 color: white;
 font-weight: bold;
 font-size: 1.3em;
}

.laX  {
  box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.50);
  font-weight: bold;
  font-size: 1.3em;
  background-color: rgba(163, 7, 7);
  height: 30px;
  width: 30px;
  border-radius: 10px;
  margin: 10px;
  position: absolute;
  right: -40px;
  top: 50%;
  transform: translateY(-50%);
  margin: 0 auto;
  cursor: pointer;
}


  .categoriaInput  {
    outline: 0;
    width: 100%;
    height: 44px;
    border-radius: 10px;
    border: 3px solid darkgray;
    background-color: white;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.30);
    text-align: left;
    color: #2c3e50;
    font-family: 'Open Sans', sans-serif;
    font-size: 1.5em;
    padding-left: 30px;
  }



  .dropDiv  {
    position: relative;
    width: 100%;
    margin-top: 20px;
  }


  .info  {
    color: grey;
    font-size: 1.3em;
    text-align: left;
    padding-left: 10px;
    vertical-align: middle;
    margin-top: 0px;
    margin-bottom: 0px;
    padding-top: 7px;
    padding-bottom: 10px;

  }

  .searchBar  {
    margin-top: 30px;
  }

  .search {
    outline: 0;
    width: 100%;
    height: 44px;
    border-radius: 25px;
    border: 3px solid darkgray;
    background-color: white;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.30);
    text-align: left;
    color: #2c3e50;
    font-family: 'Open Sans', sans-serif;
    font-size: 1.5em;
    padding-left: 30px;
}

    /* clears the 'X' from Internet Explorer */
    input[type=search]::-ms-clear {  display: none; width : 0; height: 0; }
    input[type=search]::-ms-reveal {  display: none; width : 0; height: 0; }

    /* clears the 'X' from Chrome */
    input[type="search"]::-webkit-search-decoration,
    input[type="search"]::-webkit-search-cancel-button,
    input[type="search"]::-webkit-search-results-button,
    input[type="search"]::-webkit-search-results-decoration { display: none; }



  .forAnimation  {
    width: 830px;
    margin: 0 auto;
    position: relative;
  }

  .forAnimation2  {
    width: 500px;
    margin: 0 auto;
    position: relative;
  }

  .icon  {
    z-index: 2px;
    position: absolute;
    width: 40px;
    right: -25px;
    top:5px;
  }

  .flex-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: center;
    min-height: 700PX;
  }

  @media (max-width: 550px) {
    .flex-container {
    margin: auto;
    }
  }

  @media (max-width: 1350px) {
    .forAnimation  {
      width: 600px;
      margin: 0 auto;
    }
  }

  @media (max-width: 1040px) {
    .forAnimation  {
      width: 80%;
      margin-left: 0 auto;
    }
    .forAnimation2  {
      width: 80%;
      margin-left: 0 auto;
    }
  }

  @media (max-width: 650px) {
    .categoriaInput  {
      width: 80%;
      margin-left: 10px;
    }
    .search  {
      width: 80%;
      margin-left: 10px;
    }
    .forAnimation  {
      width: 80%;
      margin-left: 5px;
    }
    .forAnimation2  {
      width: 80%;
      margin-left: 5px;
  }
}

  @media (max-width: 500px) {
    .categoriaInput  {
      font-size:0.8em;
    }
    .search  {
      font-size:0.8em;
    }
  }

</style>
