<template>
<div class="piePag">
<div class="tablaContactos">
<p class="tituloso"></p>
<div class="tableta">
<table>
<tr>
<td class="a"></td>
<td class="b"></td>
</tr>
<tr>
<td class="a"></td>
<td class="b"></td>
</tr>
</table>
</div>
</div>

<div class="direccion">
<p></p>
</div>
</div>
</template>

<script>

  export default {
    name: "piePagina",
    props: {},
    computed: {}
  };

</script>

<style scoped>
  .piePag  {
    display: flex;
    background-color: #00274C;
    width: 100%;
    justify-content: center;
    align-items:center;
  }

  .tablaContactos  {
    margin: 15px;
    margin-right: 30px;

  }

  .tituloso  {
    color: white;
    margin: 0;
    padding: 0;
    text-align: center;
    font-weight: bolder;
    font-size: 1.3em;
    margin-bottom: 5px;
  }

  .a {
    list-style-type: none;
    list-style-position: outside;
    margin-top: 15px;
    margin-bottom: 12px;
    padding-right: 7px;
    color: white;
    text-align: right;
    font-weight: bolder;
  }


  .b {
    list-style-type: none;
    list-style-position: outside;
    margin-top: 15px;
    margin-bottom: 12px;
    color: white;
    text-align: left;
  }

  .direccion  {
    vertical-align: middle;
    margin-left: 30px;
    margin-right: 30px;
  }

  .direccion p {
    color: white;
  }

  @media (max-width: 600px) {
    .piePag  {
      flex-direction: column;
    }
  }

</style>
