<template>
<div class="todoTienda">
  <umaTop/>
  <div id="tienda" class="fondo_gris">
    <div id="sideBar_div">
    </div>
    <div id="catalogos_div">
      <catalogo/>
    </div>
  </div>
</div>
<piePagina />
</template>

<script>


  import umaTop from "@/components/umaTop.vue";
  import catalogo from "@/components/catalogo.vue";
  import piePagina from "@/components/piePagina.vue";
  import bims from '@/components/listaBim.js';


  export default {
    name: 'tienda',

    components: {
      catalogo,
      umaTop,
      piePagina
    },

    data() {
      return {
        bims: bims
      }
    },

    computed: {}

  }

</script>

<style scoped>

.todoTienda  {
  background: rgb(255,255,255);
  background: linear-gradient(90deg, rgba(255,255,255,1) 55%, rgba(156,192,241,1) 100%);
}

.fondo_gris  {
display: flex;
padding-bottom: 25px;
}

#catalogos_div  {
  flex: 1;
}


@media (max-width: 570px) {
  .fondo_gris {
    flex-direction: column;
  }
}
</style>
