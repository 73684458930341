
const bims = [
//{codigo, nombre, detalles, categoria}
{codigo:'3531230501', nombre:'Escollera en talud (<=100kg)', unidades:'m^3', detalles:'Escollera clasificada de peso <=100 kg, suministro, transporte y colocación en manto y/o filtros por medios marítimos o terrestres. ', categoria:'Escolleras en talud', pliego:false, presupuesto:true, },
{codigo:'3531230502', nombre:'Escollera en talud (100-250Kg)', unidades:'m^3', detalles:'Escollera clasificada de peso entre 100-250 kg, suministro, transporte y colocación en manto y/o filtros por medios marítimos o terrestres. ', categoria:'Escolleras en talud', pliego:true, presupuesto:true, },
{codigo:'3531230503', nombre:'Escollera en talud (250-500kg)', unidades:'m^3', detalles:'Escollera clasificada de peso entre 250-500 kg, suministro, transporte y colocación en manto y/o filtros por medios marítimos', categoria:'Escolleras en talud', pliego:true, presupuesto:true, },
{codigo:'3531230505', nombre:'Escollera en talud (500-1000kg)', unidades:'m^3', detalles:'Escollera clasificada de peso entre 500-1000 kg, suministro, transporte y colocación en manto y/o filtros por medios marítimos. ', categoria:'Escolleras en talud', pliego:true, presupuesto:true, },
{codigo:'3531230507', nombre:'Escollera en talud (1000-2000kg)', unidades:'m^3', detalles:'Escollera clasificada de peso entre 1000-2000 kg, suministro, transporte y colocación en manto y/o filtros por medios marítimos o terrestres. ', categoria:'Escolleras en talud', pliego:true, presupuesto:true, },
{codigo:'3531230508', nombre:'Escollera en talud (2000-3000kg)', unidades:'m^3', detalles:'Escollera  clasificada de peso entre 2000-3000 kg, suministro, transporte y colocación en manto y/o filtros por medios marítimos o terrestres.', categoria:'Escolleras en talud', pliego:true, presupuesto:true, },
{codigo:'3531230509', nombre:'Escollera en talud (3000-5000kg)', unidades:'m^3', detalles:'Escollera  clasificada de peso entre 3000-5000 kg, suministro, transporte y colocación en manto de dique y/o filtros por medios marítimos o terrestres.', categoria:'Escolleras en talud', pliego:true, presupuesto:true, },
{codigo:'3531231001', nombre:'Escollera banqueta de cajones (<=100kg)', unidades:'m^3', detalles:'Escollera clasificada de peso <=100 kg, suministro, transporte y colocación en núcleo o manto de banqueta de cimentación de cajones por medios marítimos o terrestres. ', categoria:'Escolleras en banquetas de cajones', pliego:false, presupuesto:true, },
{codigo:'3531231002', nombre:'Escollera banqueta de cajones (100-500kg)', unidades:'m^3', detalles:'Escollera clasificada de peso entre 100-500 kg, suministro, transporte y colocación en núcleo o manto de banqueta de cimentación de cajones por medios marítimos o terrestres. ', categoria:'Escolleras en banquetas de cajones', pliego:false, presupuesto:true, },
{codigo:'3531231005', nombre:'Escollera banqueta de cajones (>2000kg)', unidades:'m^3', detalles:'Escollera clasificada de peso > 2000 kg, suministro, transporte y colocación en núcleo o manto de banqueta de cimentación de cajones por medios marítimos o terrestres. ', categoria:'Escolleras en banquetas de cajones', pliego:false, presupuesto:true, },
{codigo:'3123232301', nombre:'Pedraplén (medios terrestres)', unidades:'m^3', detalles:'Pedraplén colocado en trasdós de cajones o muelle de bloques o tablestacas ejecutado por medios terrestres. ', categoria:'Pedraplén', pliego:true, presupuesto:true, },
{codigo:'3123232302', nombre:'Pedraplén (medios marítimos)', unidades:'m^3', detalles:'Pedraplén colocado en trasdós de cajones o muelle de bloques o tablestacas ejecutado por medios marítimo. ', categoria:'Pedraplén', pliego:false, presupuesto:true, },
{codigo:'3559331301', nombre:'Bolardo (<50 Tn)', unidades:'Ud', detalles:'Bolardo de <50 Tn de tiro nominal totalmente colocado, incluso pernos de anclaje y demás elementos necesarios para su perfecta puesta en servicio. ', categoria:'Bolardos', pliego:false, presupuesto:true, },
{codigo:'35591305', nombre:'Defensa tipo SC (< SC-1000)', unidades:'Ud', detalles:'Defensa tipo SC < SC-1000, totalmente instalada.', categoria:'Defensas', pliego:false, presupuesto:true, },
{codigo:'35591309', nombre:'Defensa doble tipo SC (< SC-1200)', unidades:'Ud', detalles:'Defensa doble tipo SC < SC-1200, totalmente instalada', categoria:'Defensas', pliego:false, presupuesto:true, },
{codigo:'3162131307', nombre:'Pilote fabricado in situ d= 1,5 m (medio marítimo)', unidades:'m', detalles:'Pilote fabricado in situ d= 1,5 m, incluyendo excavación y hormigonado excluida armadura, por medios marítimos. ', categoria:'Pilotes', pliego:false, presupuesto:true, },
{codigo:'3162131309', nombre:'Pilote fabricado in situ d= 1,8 m (medio terrestre)', unidades:'m', detalles:'Pilote fabricado in situ d= 1,8 m, incluyendo excavación y hormigonado excluida armadura, por medios terrestres. ', categoria:'Pilotes', pliego:false, presupuesto:true, },
{codigo:'32131306', nombre:'Pavimento de hormigón HF-4,0 ', unidades:'m^3', detalles:'Pavimento de hormigón HF-4,0 para tráfico pesado (denominación anterior HP-40), tamaño de árido de 40 mm, resistencia característica a flexión >= 4 Mpa., incluso adquisición, transporte, vertido, extendido, vibrado mecánico, encofrado, curado, desencofrado, juntas de construcción efectuadas mediante fresno, replanteo y nivelación de la superficie acabada.  ', categoria:'Pavimentación: Pavimentos de hormigón', pliego:false, presupuesto:false, },
{codigo:'32131307', nombre:'Pavimento de hormigón HF-4,5', unidades:'m^3', detalles:'Pavimento de hormigón HF-4,5 de resistencia a flexotracción (denominación anterior HP-45), incluso adquisición, transporte, vertido, extendido, vibrado mecánico, encofrado, curado, desencofrado, juntas de construcción efectuadas mediante fresno, replanteo y nivelación de la superficie acabada. ', categoria:'Pavimentación: Pavimentos de hormigón', pliego:false, presupuesto:false, },
{codigo:'03700005', nombre:'Hormigón en masa HM-30 colocado en alzados o espaldones', unidades:'m^3', detalles:'Hormigón en masa HM-30 colocado en alzados o espaldones, incluso encofrado, desencofrado, vibrado y curado. ', categoria:'Hormigón en masa', pliego:true, presupuesto:true, },
{codigo:'03700006', nombre:'Hormigón en masa HM-30 colocado en losas o cimientos', unidades:'m^3', detalles:'Hormigón en masa HM-30 colocado en losas o cimientos, incluso encofrado, desencofrado, vibrado y curado.', categoria:'Hormigón en masa', pliego:true, presupuesto:true, },
{codigo:'3123230501', nombre:'Todo uno de cantera, núcleo (medios marítimos)', unidades:'m^3', detalles:'Todo uno de cantera, colocado en formación del núcleo del dique ejecutado por medios marítimos', categoria:'Todo uno', pliego:true, presupuesto:true, },
{codigo:'3123230502', nombre:'Todo uno de cantera, núcleo ( medios terrestres)', unidades:'m^3', detalles:'Todo uno de cantera, colocado en formación del núcleo del dique ejecutado por medios terrestres.', categoria:'Todo uno', pliego:false, presupuesto:true, },
{codigo:'3123231301', nombre:'Relleno general (Procedente de dragado)', unidades:'m^3', detalles:'Relleno general de la explanada procedente de dragado. ', categoria:'Relleno', pliego:false, presupuesto:true, },
{codigo:'3123231302', nombre:'Relleno general (Procedencia terrestre)', unidades:'m^3', detalles:'Relleno general de la explanada de procedencia terrestre. ', categoria:'Relleno', pliego:true, presupuesto:true, },
{codigo:'3123231304', nombre:'Relleno seleccionado en coronación de explanada', unidades:'m^3', detalles:'Relleno seleccionado en coronación de explanada, extendido y compactado de procedencia terrestre.', categoria:'Relleno', pliego:false, presupuesto:true, },
{codigo:'3123231305', nombre:'Relleno seleccionado en coronación de cajones', unidades:'m^3', detalles:'Relleno seleccionado en coronación de cajones, extendido y compactado de procedencia terrestre. ', categoria:'Relleno', pliego:false, presupuesto:true, },
{codigo:'3123231306', nombre:'Relleno granular en celdas de cajones', unidades:'m^3', detalles:'Relleno granular en celdas de cajones, incluso suministros, transporte y vertido, totalmente acabado procedente de dragado. ', categoria:'Relleno', pliego:false, presupuesto:true, },
{codigo:'3531291601', nombre:'Hormigón en bloques de peso  <15 Tn (medio marítimo)', unidades:'m^3', detalles:'Hormigón en bloques de peso  <15 Tn, fabricación, transporte y colocación por medio marítimo. ', categoria:'Bloques de hormigón', pliego:false, presupuesto:true, },
{codigo:'3531291602', nombre:'Hormigón en bloques de peso  <15 Tn (medio terrestre)', unidades:'m^3', detalles:'Hormigón en bloques de peso  <15 Tn, fabricación, transporte y colocación por medio terrestre. ', categoria:'Bloques de hormigón', pliego:false, presupuesto:true, },
{codigo:'3531291605', nombre:'Hormigón en bloques de peso entre 60-100 Tn', unidades:'m^3', detalles:'Hormigón en bloques de peso entre 60-100 Tn, transporte y colocación por medios terrestres', categoria:'Bloques de hormigón', pliego:false, presupuesto:true, },
{codigo:'3531291612', nombre:'Hormigón en bloques de guarda de hormigón', unidades:'m^3', detalles:'Hormigón en bloques de guarda de hormigón, transporte y colocación. ', categoria:'Bloques de hormigón', pliego:false, presupuesto:true, },
{codigo:'03201001', nombre:'Hormigón para armar HA-30 colocado en cajones', unidades:'m^3', detalles:'Hormigón para armar HA-30 colocado en cajones, incluso encofrado, desencofrado, vibrado y curado.', categoria:'Hormigón para armar', pliego:true, presupuesto:true, },
{codigo:'03201002', nombre:'Hormigón para armar HA-30 colocado en superestructura', unidades:'m^3', detalles:'Hormigón para armar HA-30 colocado en superestructura, incluso encofrado, desencofrado, vibrado y curado.', categoria:'Hormigón para armar', pliego:true, presupuesto:true, },
{codigo:'03201003', nombre:'Hormigón para armar HA-35 colocado en cajones', unidades:'m^3', detalles:'Hormigón para armar HA-35 colocado en cajones, incluso encofrado, desencofrado, vibrado y curado.', categoria:'Hormigón para armar', pliego:true, presupuesto:true, },
{codigo:'03201004', nombre:'Hormigón para armar HA-35 colocado en superestructura', unidades:'m^3', detalles:'Hormigón para armar HA-35 colocado en superestructura, incluso encofrado, desencofrado, vibrado y curado. ', categoria:'Hormigón para armar', pliego:true, presupuesto:true, },
{codigo:'03201006', nombre:'Hormigón para armar HA-30  colocado en alzados', unidades:'m^3', detalles:'Hormigón para armar HA-30  colocado en alzados o espaldones, incluso encofrado, desencofrado, vibrado y curado. ', categoria:'Hormigón para armar', pliego:false, presupuesto:true, },
{codigo:'03201007', nombre:'Hormigón para armar HA-30  colocado en losas o cimientos', unidades:'m^3', detalles:'Hormigón para armar HA-30  colocado en losas o cimientos, incluso encofrado, desencofrado, vibrado y curado.', categoria:'Hormigón para armar', pliego:true, presupuesto:true, },
{codigo:'310516', nombre:'Grava colocada en base de banqueta de cimentación de cajones', unidades:'m^3', detalles:'Grava colocada en base de banqueta de cimentación de cajones, para regularización de la superficie y como protección de cabeza de columnas de grava, incluso adquisición, carga, transporte, vertido con gánguil y nivelación, con ayuda de buzos.', categoria:'Mejora del terreno y cimentaciones', pliego:true, presupuesto:true, },
{codigo:'32110001', nombre:'Zahorra artificial', unidades:'m^3', detalles:'Zahorra artificial en formación de base, extendida y compactada.', categoria:'Pavimentación: Bases y sub-bases', pliego:false, presupuesto:false, },
{codigo:'03201001(1)', nombre:'Hormigón para armar HA-30 colocado en cajones', unidades:'m^3', detalles:'Hormigón para armar HA-30 colocado en cajones, incluso encofrado, desencofrado, vibrado y curado.', categoria:'Hormigón para armar', pliego:false, presupuesto:true, },
{codigo:'03201001(2)', nombre:'Hormigón para armar HA-30 colocado en cajones', unidades:'m^3', detalles:'Hormigón para armar HA-30 colocado en cajones, incluso encofrado, desencofrado, vibrado y curado.', categoria:'Hormigón para armar', pliego:false, presupuesto:true, },
{codigo:'03201002(1)', nombre:'Hormigón para armar HA-30 colocado en superestructura', unidades:'m^3', detalles:'Hormigón para armar HA-30 colocado en superestructura, incluso encofrado, desencofrado, vibrado y curado.', categoria:'Hormigón para armar', pliego:false, presupuesto:true, },
{codigo:'03700005(1)', nombre:'Hormigón en masa HM-30 colocado en alzados o espaldones', unidades:'m^3', detalles:'Hormigón en masa HM-30 colocado en alzados o espaldones, incluso encofrado, desencofrado, vibrado y curado. ', categoria:'Hormigón en masa', pliego:false, presupuesto:true, },
{codigo:'03700005(2)', nombre:'Hormigón en masa HM-30 colocado en alzados o espaldones', unidades:'m^3', detalles:'Hormigón en masa HM-30 colocado en alzados o espaldones, incluso encofrado, desencofrado, vibrado y curado. ', categoria:'Hormigón en masa', pliego:false, presupuesto:true, },
{codigo:'03700006(1)', nombre:'Hormigón en masa HM-30 colocado en losas o cimientos', unidades:'m^3', detalles:'Hormigón en masa HM-30 colocado en losas o cimientos, incluso encofrado, desencofrado, vibrado y curado.', categoria:'Hormigón en masa', pliego:false, presupuesto:true, },
{codigo:'310516(1)', nombre:'Grava colocada en base de banqueta de cimentación de cajones', unidades:'m^3', detalles:'Grava colocada en base de banqueta de cimentación de cajones, para regularización de la superficie y como protección de cabeza de columnas de grava, incluso adquisición, carga, transporte, vertido con gánguil y nivelación, con ayuda de buzos.', categoria:'Mejora del terreno y cimentaciones', pliego:false, presupuesto:true, },
{codigo:'3123230501(1)', nombre:'Todo uno de cantera, núcleo (medios marítimos)', unidades:'m^3', detalles:'Todo uno de cantera, colocado en formación del núcleo del dique ejecutado por medios marítimos', categoria:'Todo uno', pliego:true, presupuesto:true, },
{codigo:'3123230501(2)', nombre:'Todo uno de cantera, núcleo (medios marítimos)', unidades:'m^3', detalles:'Todo uno de cantera, colocado en formación del núcleo del dique ejecutado por medios marítimos', categoria:'Todo uno', pliego:true, presupuesto:true, },
{codigo:'3123230501(3)', nombre:'Todo uno de cantera, núcleo (medios marítimos)', unidades:'m^3', detalles:'Todo uno de cantera, colocado en formación del núcleo del dique ejecutado por medios marítimos', categoria:'Todo uno', pliego:true, presupuesto:true, },
{codigo:'3123230502(1)', nombre:'Todo uno de cantera, núcleo ( medios terrestres)', unidades:'m^3', detalles:'Todo uno de cantera, colocado en formación del núcleo del dique ejecutado por medios terrestres.', categoria:'Todo uno', pliego:false, presupuesto:true, },
{codigo:'3123230502(2)', nombre:'Todo uno de cantera, núcleo ( medios terrestres)', unidades:'m^3', detalles:'Todo uno de cantera, colocado en formación del núcleo del dique ejecutado por medios terrestres.', categoria:'Todo uno', pliego:false, presupuesto:true, },
{codigo:'3123230502(3)', nombre:'Todo uno de cantera, núcleo ( medios terrestres)', unidades:'m^3', detalles:'Todo uno de cantera, colocado en formación del núcleo del dique ejecutado por medios terrestres.', categoria:'Todo uno', pliego:false, presupuesto:true, },
{codigo:'3123230502(4)', nombre:'Todo uno de cantera, núcleo ( medios terrestres)', unidades:'m^3', detalles:'Todo uno de cantera, colocado en formación del núcleo del dique ejecutado por medios terrestres.', categoria:'Todo uno', pliego:false, presupuesto:true, },
{codigo:'3123231301(1)', nombre:'Relleno general (Procedente de dragado)', unidades:'m^3', detalles:'Relleno general de la explanada procedente de dragado. ', categoria:'Relleno', pliego:false, presupuesto:true, },
{codigo:'3123231304(1)', nombre:'Relleno seleccionado en coronación de explanada', unidades:'m^3', detalles:'Relleno seleccionado en coronación de explanada, extendido y compactado de procedencia terrestre.', categoria:'Relleno', pliego:false, presupuesto:true, },
{codigo:'3123231306(1)', nombre:'Relleno granular en celdas de cajones', unidades:'m^3', detalles:'Relleno granular en celdas de cajones, incluso suministros, transporte y vertido, totalmente acabado procedente de dragado. ', categoria:'Relleno', pliego:false, presupuesto:true, },
{codigo:'3123231306(2)', nombre:'Relleno granular en celdas de cajones', unidades:'m^3', detalles:'Relleno granular en celdas de cajones, incluso suministros, transporte y vertido, totalmente acabado procedente de dragado. ', categoria:'Relleno', pliego:false, presupuesto:true, },
{codigo:'3123232302(1)', nombre:'Pedraplén (medios marítimos)', unidades:'m^3', detalles:'Pedraplén colocado en trasdós de cajones o muelle de bloques o tablestacas ejecutado por medios marítimo. ', categoria:'Pedraplén', pliego:false, presupuesto:true, },
{codigo:'32131306(1)', nombre:'Pavimento de hormigón HF-4,0 ', unidades:'m^3', detalles:'Pavimento de hormigón HF-4,0 para tráfico pesado (denominación anterior HP-40), tamaño de árido de 40 mm, resistencia característica a flexión >= 4 Mpa., incluso adquisición, transporte, vertido, extendido, vibrado mecánico, encofrado, curado, desencofrado, juntas de construcción efectuadas mediante fresno, replanteo y nivelación de la superficie acabada.  ', categoria:'Pavimentación: Pavimentos de hormigón', pliego:false, presupuesto:false, },
{codigo:'32131306(2)', nombre:'Pavimento de hormigón HF-4,0 ', unidades:'m^3', detalles:'Pavimento de hormigón HF-4,0 para tráfico pesado (denominación anterior HP-40), tamaño de árido de 40 mm, resistencia característica a flexión >= 4 Mpa., incluso adquisición, transporte, vertido, extendido, vibrado mecánico, encofrado, curado, desencofrado, juntas de construcción efectuadas mediante fresno, replanteo y nivelación de la superficie acabada.  ', categoria:'Pavimentación: Pavimentos de hormigón', pliego:false, presupuesto:false, },
{codigo:'3531230501(1)', nombre:'Escollera en talud (<=100kg)', unidades:'m^3', detalles:'Escollera clasificada de peso <=100 kg, suministro, transporte y colocación en manto y/o filtros por medios marítimos o terrestres. ', categoria:'Escolleras en talud', pliego:false, presupuesto:true, },
{codigo:'3531230502(1)', nombre:'Escollera en talud (100-250Kg)', unidades:'m^3', detalles:'Escollera clasificada de peso entre 100-250 kg, suministro, transporte y colocación en manto y/o filtros por medios marítimos o terrestres. ', categoria:'Escolleras en talud', pliego:false, presupuesto:true, },
{codigo:'3531230502(2)', nombre:'Escollera en talud (100-250Kg)', unidades:'m^3', detalles:'Escollera clasificada de peso entre 100-250 kg, suministro, transporte y colocación en manto y/o filtros por medios marítimos o terrestres. ', categoria:'Escolleras en talud', pliego:false, presupuesto:true, },
{codigo:'3531230505(1)', nombre:'Escollera en talud (500-1000kg)', unidades:'m^3', detalles:'Escollera clasificada de peso entre 500-1000 kg, suministro, transporte y colocación en manto y/o filtros por medios marítimos. ', categoria:'Escolleras en talud', pliego:false, presupuesto:true, },
{codigo:'3531230508(1)', nombre:'Escollera en talud (2000-3000kg)', unidades:'m^3', detalles:'Escollera  clasificada de peso entre 2000-3000 kg, suministro, transporte y colocación en manto y/o filtros por medios marítimos o terrestres.', categoria:'Escolleras en talud', pliego:false, presupuesto:true, },
{codigo:'3531230509(1)', nombre:'Escollera en talud (3000-5000kg)', unidades:'m^3', detalles:'Escollera  clasificada de peso entre 3000-5000 kg, suministro, transporte y colocación en manto de dique y/o filtros por medios marítimos o terrestres.', categoria:'Escolleras en talud', pliego:false, presupuesto:true, },
{codigo:'3531230509(2)', nombre:'Escollera en talud (3000-5000kg)', unidades:'m^3', detalles:'Escollera  clasificada de peso entre 3000-5000 kg, suministro, transporte y colocación en manto de dique y/o filtros por medios marítimos o terrestres.', categoria:'Escolleras en talud', pliego:false, presupuesto:true, },
{codigo:'3531230509(3)', nombre:'Escollera en talud (3000-5000kg)', unidades:'m^3', detalles:'Escollera  clasificada de peso entre 3000-5000 kg, suministro, transporte y colocación en manto de dique y/o filtros por medios marítimos o terrestres.', categoria:'Escolleras en talud', pliego:false, presupuesto:true, },
{codigo:'3531231001(1)', nombre:'Escollera banqueta de cajones (<=100kg)', unidades:'m^3', detalles:'Escollera clasificada de peso <=100 kg, suministro, transporte y colocación en núcleo o manto de banqueta de cimentación de cajones por medios marítimos o terrestres. ', categoria:'Escolleras en banquetas de cajones', pliego:false, presupuesto:true, },
{codigo:'3531291605(1)', nombre:'Hormigón en bloques de peso entre 60-100 Tn', unidades:'m^3', detalles:'Hormigón en bloques de peso entre 60-100 Tn, transporte y colocación por medios terrestres', categoria:'Bloques de hormigón', pliego:false, presupuesto:true, },
{codigo:'3531291612(1)', nombre:'Hormigón en bloques de guarda de hormigón', unidades:'m^3', detalles:'Hormigón en bloques de guarda de hormigón, transporte y colocación. ', categoria:'Bloques de hormigón', pliego:false, presupuesto:true, },
]

export default bims
